import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Load initial appointments data from sessionStorage if it exists
const loadInitialAppointments = () => {
  const storedAppointments = sessionStorage.getItem('appointments');
  return storedAppointments ? JSON.parse(storedAppointments) : [];
};

export const fetchAppointments  = createAsyncThunk(
    'appointments/fetchAppointments',
    async (req_data) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/athenahealth/appointmentinteral`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${req_data.token}`,
            },
            body: JSON.stringify({
                user_id: req_data.user_id,
                temp_token: req_data.temp_token,
                practice_id: req_data.practice_id,
                department_id: req_data.department_id,
                provider_id: req_data.provider_id,
                from_date: req_data.from_date,
                to_date: req_data.to_date,
            })
        });
        return response.json();
    }
);

const appointmentsSlice = createSlice({
    name: "appointments",
    initialState: {
        isLoading: false,
        appointment_data: loadInitialAppointments(),
        isError: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAppointments.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchAppointments.fulfilled, (state, action) => {
            state.isLoading = false;
            state.appointment_data = action.payload;

            // Save the fetched appointments to sessionStorage
            sessionStorage.setItem('appointments', JSON.stringify(action.payload));
        });
        builder.addCase(fetchAppointments.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            console.log("Error: ", action.payload);
        });
    }
});

export default appointmentsSlice.reducer;
