import { createSlice } from '@reduxjs/toolkit';

const appointments = sessionStorage.getItem('athenaAppointments')
    ? JSON.parse(sessionStorage.getItem('athenaAppointments'))
    : null;

const initialState = {
    appointments: appointments || null,
};
const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        setAppointments: (state, action) => {
        return {
            ...state,
            appointments: action.payload.appointments,
        };
        },
    },
});


// export const { setAppointments } = appointmentSlice.actions
export default appointmentSlice.reducer;
