import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const userSession = sessionStorage.getItem('appointmentDates') ? JSON.parse(sessionStorage.getItem('appointmentDates')) : null;

const initialState = {
    startDate: userSession ? userSession.startDate : moment().format('MM/DD/YYYY'),
    endDate: userSession ? userSession.endDate : moment().format('MM/DD/YYYY'),
  
};

const appointmentDatesSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setAppointmentDate(state, action) {
        state.startDate = action.payload.startDate;
        state.endDate = action.payload.endDate;
    },
    resetAppointmentDates(state) {
        state.startDate= moment().format('MM/DD/YYYY');
        state.endDate= moment().format('MM/DD/YYYY');
    },
  },
});

export const { setAppointmentDate, resetAppointmentDates } = appointmentDatesSlice.actions;

export default appointmentDatesSlice.reducer;
