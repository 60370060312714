import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const fetchDepartments  = createAsyncThunk(
    'departments/fetchDepartments',
    async (req_data) => {
        console.log("req_data: ", req_data);
        // return req_data
        const response = await fetch(`${process.env.REACT_APP_API_URL}/athenahealth/departments`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${req_data.token}`,
            },
            body: JSON.stringify({
                user_id: req_data.user_id,
                temp_token: req_data.temp_token,
                practice_id: req_data.practice_id,
            })
        })
        return response.json()
    }
)

const departmentsSlice = createSlice({
    name:"departments",
    initialState:{
        isLoading : false,
        department_data : [],
        isError: null
    },
    extraReducers:  (builder) => {
        builder.addCase(fetchDepartments.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchDepartments.fulfilled, (state, action) => {
            // console.log("action.payload: ", action.payload.departments.departments);
            if(action.payload.status){
                
                state.isError = false
                state.isLoading = false
                state.department_data = action.payload.departments.departments
            }else{
                state.isError = true
                state.isLoading = false
                state.department_data = []
            }
        })
        builder.addCase(fetchDepartments.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            // console.log("Error: ", action.payload);
        })
        
    }
})


export default departmentsSlice.reducer;