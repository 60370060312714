import React from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import { toast } from "react-hot-toast";
const PrivateRoute = ({
  children,
  redirectLink = "/account/",
  isSubscriptionRequired = false,
}) => {
  const { userAuth, userCurrentSubscription } = useUserContext();

  if (userAuth && isSubscriptionRequired && userCurrentSubscription?.length <= 0) {
    toast.error("Please activate subscription plan", { duration: 1500 });
    return <Navigate to={"/account/current-subscription"} />;
  }

  return userAuth ? children : <Navigate to={redirectLink} />;
};

export default PrivateRoute;
