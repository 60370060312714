import { createContext, useContext, useState } from "react";

const UIContext = createContext({});

export const UIProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(true);
  const [activeMenu, setActiveMenu] = useState(1);
  const [isMobileSize, setMobileSize] = useState(false);


  const shareValue = {
    showMenu,
    setShowMenu,

    activeMenu,
    setActiveMenu,

    isMobileSize, 
    setMobileSize
  };

  return <UIContext.Provider value={shareValue}>{children}</UIContext.Provider>;
};

export function useUIContext() {
  return useContext(UIContext);
}
