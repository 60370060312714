// import createSlice from @reduxjs/toolkit
import { createSlice } from '@reduxjs/toolkit';

const userSession = sessionStorage.getItem('athenahealth') ? JSON.parse(sessionStorage.getItem('athenahealth')) : null;

const initialState = {
  // login_status: userSession ? userSession.loginStatus : false,
  // temp_token: userSession ? userSession.token.access_token : null,
  // user_id: userSession ? userSession.user_id : null,
  practice_id: userSession ? userSession.practiceId : null,
  department_id: userSession ? userSession.departmentId : null,
  
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession(state, action) {
      state.login_status = action.payload.login_status;
      state.practice_id = action.payload.practice_id;
      state.department_id = action.payload.department_id;
    },
    clearSession(state) {
      state.login_status = false;
      state.practice_id = null;
      state.department_id = null;
    },
  },
});

export const { setSession, clearSession } = sessionSlice.actions;

export default sessionSlice.reducer;