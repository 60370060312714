import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import { UIProvider } from "./context/UiContext";
import { Provider } from 'react-redux'
import {store} from './redux/store'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      {/* <App/> */}
      <UserProvider>
        <UIProvider>
          <App />
        </UIProvider>
      </UserProvider>
    </BrowserRouter>
  </Provider>,
);
