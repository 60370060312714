export function calculateRemainingDays(endDate) {
  // Convert the start and end dates to Date objects
  const endDateObj = new Date(endDate);
  const currentDate = new Date();

  // Calculate the time difference in milliseconds between the end date and current date
  const timeDifference = endDateObj - currentDate;
  const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return remainingDays;
  // return remainingDays > 0 ? remainingDays : false;
}

export function calculateRemainingDaysResult(endDate) {
  const result = calculateRemainingDays(endDate);
  return result > 0 ? `${result} days` : "Subscription plan has been expired";
}
